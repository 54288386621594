import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import { Color } from '../_shared/enums/color';
import PageLayout, { PageLayoutBackground } from '../components/common/page-layout/page-layout';
import HeadlineCopytext from '../components/templates/headline-copytext/headline-copytext';
import ResponsiveImage from '../components/templates/responsive-image/responsive-image';

import NotFoundStyles from './_scss/404.module.scss';

const NotFoundPage = () => {
    const intl = useIntl();
    return (
        <PageLayout
            title={intl.formatMessage({ id: 'pages.404.title' })}
            description={''}
            pageBackground={PageLayoutBackground.PinkAqua}
        >
            <div className={NotFoundStyles.notFoundContent}>
                <HeadlineCopytext
                    color={Color.Aqua}
                    alignment="left"
                    headline={[
                        {
                            style: 'h2',
                            text: intl.formatMessage({ id: 'pages.404.headlines.0' }),
                        },
                        {
                            style: 'h4',
                            text: intl.formatMessage({ id: 'pages.404.headlines.1' }),
                        },
                        {
                            style: 'h3',
                            text: intl.formatMessage({ id: 'pages.404.headlines.2' }),
                        },
                    ]}
                >
                    <p>
                        <FormattedMessage id="pages.404.copytext.0" />
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <FormattedMessage id="pages.404.copytext.1" />
                    </p>
                </HeadlineCopytext>
            </div>

            <div className={NotFoundStyles.notFoundImage}>
                <ResponsiveImage
                    images={[
                        {
                            path: '_assets/pages/404/404.png',
                            media: '',
                        },
                    ]}
                    altText=""
                />
            </div>
        </PageLayout>
    );
};

export default NotFoundPage;
